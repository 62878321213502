import * as React from "react";

import {
  Edit as RAEdit,
  SimpleForm,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  useUnselectAll,
  useNotify,
} from "react-admin";
import { useFormState, useForm } from "react-final-form";

import { HorizontalGroup } from "../../../reusable";
import { Button as MuiButton } from "@material-ui/core";

import { List as RAList, Datagrid, TextField } from "react-admin";
import filters from "../../pmDeals/List/Filters";

import { useStyles } from "../../pmDeals/List/List";
import { MarketReferenceField } from "../../../market";
import { WebsiteReferenceField, WebsiteReferenceInput } from "../../../website";
import { YoutubeReferenceField } from "../../../youtube/fields/YoutubeReferenceField";
import { RESOURCE as PMLinks } from "../../pmDeals/constants";
import { API_URL } from "../constants";

const BulkActionButtons = ({ setSelectedData, ...props }) => {
  const { selectedIds } = props;
  React.useEffect(() => {
    const fetchData = async () => {
      const promises = selectedIds.map((id) => {
        return fetch(`${API_URL}/${PMLinks}/${id}/`)
          .then((response) => response.json())
          .then((data) => data);
      });
      const data = await Promise.all(promises);
      const items = data.map((item, index) => {
        return {
          position: index + 1,
          brand_pk: item.brand_pk,
          pretty_link: item.pretty_link,
          brand_name: item.brand_name,
        };
      });
      setSelectedData(items);
    };
    fetchData();
  }, [selectedIds, setSelectedData]);
  return <></>;
};

export default function LinksReferenceList({ setSelectedData, ...props }) {
  const classes = useStyles();
  return (
    <RAList
      className={classes.fullWidth}
      resource={PMLinks}
      basePath={API_URL}
      filters={filters}
      exporter={false}
      bulkActionButtons={
        <BulkActionButtons setSelectedData={setSelectedData} />
      }
    >
      <Datagrid>
        <TextField source={"brand_name"} />
        <MarketReferenceField source={"market"} />
        <TextField label={"Channel"} source={"data.channel"} />
        <WebsiteReferenceField
          className={classes.textBreak}
          source={"website"}
        />
        <YoutubeReferenceField
          className={classes.textBreak}
          source={"youtube"}
        />
        <TextField source={"data.product"} label={"Product"} />
        <TextField
          className={classes.textBreak}
          source={"data.campaign"}
          label={"Campaign"}
        />
        <TextField source={"pretty_link"} label={"Pretty Link"} />
      </Datagrid>
    </RAList>
  );
}
