import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";

import { List } from "./List";
import BulkCreate from "./BulkCreate";
import InsertCommentIcon from "@material-ui/icons/InsertComment";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeoutreachLinkbuildingOrder = (permissions) => [
  permissions.groups.includes(GROUPS.linkbuilding) ? (
    <Resource
      name={RESOURCE.outreachLinkbuildingOrder}
      key={RESOURCE.outreachLinkbuildingOrder}
      options={{ label: "Link Database" }}
      list={List}
      create={BulkCreate}
      icon={InsertCommentIcon}
    />
  ) : (
    <Resource
      name={RESOURCE.outreachLinkbuildingOrder}
      key={RESOURCE.outreachLinkbuildingOrder}
    />
  ),
  // <Resource
  //     name={ RESOURCE.outreachLinkbuildingIndex}
  //     key={ RESOURCE.outreachLinkbuildingIndex }
  // />,
];
