import * as React from "react";

import {
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from "react-admin";

import { List as RAList, Datagrid, TextField } from "react-admin";
import { filters } from "../../landingPage/List";

import { useStyles } from "../../pmDeals/List/List";
import { WebsiteReferenceField } from "../../../website";
import { RESOURCE as LandingPage } from "../../landingPage/constants";
import { API_URL } from "../constants";

const BulkActionButtons = ({ setSelectedData, ...props }) => {
  const { selectedIds } = props;
  React.useEffect(() => {
    const fetchData = async () => {
      const promises = selectedIds.map((id) => {
        return fetch(`${API_URL}/${LandingPage}/${id}/`)
          .then((response) => response.json())
          .then((data) => data);
      });
      const data = await Promise.all(promises);
      const items = data.map((item, index) => {
        return {
          domain: item.domain,
          landing_page: item.landing_page_url,
        };
      });
      setSelectedData(items);
    };
    fetchData();
  }, [selectedIds, setSelectedData]);
  return <></>;
};

export default function LandingPageReferenceList({
  setSelectedData,
  ...props
}) {
  const classes = useStyles();
  return (
    <RAList
      className={classes.fullWidth}
      resource={LandingPage}
      basePath={API_URL}
      filters={filters}
      exporter={false}
      hasCreate={false}
      hasEdit={false}
      bulkActionButtons={
        <BulkActionButtons setSelectedData={setSelectedData} />
      }
    >
      <Datagrid>
        <WebsiteReferenceField source="domain" label="Domain" />
        <TextField source="brand_name" label="Slug" />
        <ArrayField source="data.links" label="Links">
          <SingleFieldList>
            <ChipField source="pretty_link" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Landing Page Link"
          render={(record) => {
            return record && record.landing_page_url ? (
              <a
                href={record.landing_page_url}
                rel="noreferrer"
                target="_blank"
              >
                {record.landing_page_url}
              </a>
            ) : null;
          }}
        />
      </Datagrid>
    </RAList>
  );
}
