import * as React from "react";
import { Loading, NumberInput, useDataProvider } from "react-admin";
import debounce from "lodash.debounce";

import { RESOURCE as SECTION_RESOURCE } from "../section/constants";
import {
  SectionTextInput,
  ShowSectionText,
} from "../../reusable/fields/SectionTextInput";
import { CopyAccordionShow } from "../../page/show/IntegratorPageShow";
import { TextField } from "@material-ui/core";

const DisplayContentSections = ({
  record,
  integrator = false,
  isFreelance = false,
  disabled = false,
  show = false,
}) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  React.useEffect(() => {
    setLoading(true);
    dataProvider
      .getList(SECTION_RESOURCE, {
        pagination: {
          page: 1,
          perPage: 25,
        },
        sort: { field: "position", order: "ASC" },
        filter: {
          content: record.id,
        },
      })
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [dataProvider, record.id]);

  if (loading) return <Loading />;
  /**
   * only display the content for the
   * show component
   * */
  if (show) {
    return (
      <>
        {Object.values(data).map((section, index) => (
          <ShowSectionText
            record={section}
            label={section.title}
            key={`input-${index}`}
            id={`${section.title}-${index}`}
            sourceValue={section.content_text}
            suggestedWords={section.requested_words}
            disabled={disabled}
            fullWidth
            countWords
          />
        ))}
      </>
    );
  }

  /**
   * Display Accordion if the integrator is viewing the content
   */
  if (integrator) {
    return (
      <>
        {Object.values(data).map((section, index) => (
          <CopyAccordionShow
            id={`accordion-${index}`}
            record={section}
            source={"content"}
            label={`${section.title}`}
            rich
            key={index}
          />
        ))}
      </>
    );
  }

  const RequestedWordsInput = ({ id, title, requested_words }) => {
    const [words, setWords] = React.useState(requested_words);
    const dataProvider = useDataProvider();

    const handleChange = debounce((value) => {
      dataProvider
        .update(SECTION_RESOURCE, {
          id: id,
          data: {
            requested_words: value,
          },
        })
        .then(({ data }) => {
          setWords(data?.requested_words);
        })
        .catch((err) => {
          console.err(err);
        });
    }, 500);

    return (
      <TextField
        fullWidth
        name={`Requested Words for ${title}`}
        label={`Requested Words for ${title}`}
        variant="filled"
        defaultValue={words}
        // value={words}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    );
  };

  return (
    <>
      {Object.values(data).map((section, index) => {
        return [
          isFreelance ? null : (
            <RequestedWordsInput
              id={section.id}
              title={section.title}
              requested_words={section.requested_words}
            />
          ),
          <SectionTextInput
            record={section}
            noSpaces={record.language_has_no_spaces}
            label={section.title}
            key={`input-${index}`}
            id={`${section.title}-${index}`}
            sourceValue={section.content_text}
            suggestedWords={section.requested_words}
            disabled={disabled}
            fullWidth
            countWords
          />,
        ];
      })}
    </>
  );
};

export default DisplayContentSections;
