import React from "react";
import Edit from "./Edit";

import {
  List as RAList,
  Datagrid,
  ListProps,
  EditButton,
  SearchInput,
} from "react-admin";
import EditableTextField from "../../reusable/fields/EditableTextField";

const filters = [<SearchInput source="q" alwaysOn />];

export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList filters={filters} {...props}>
      <Datagrid expand={<Edit />}>
        <EditableTextField
          partialUpdate
          source={"name"}
          label={"Network Name"}
        />
        <EditableTextField partialUpdate source={"link"} label={"Link"} />
        <EditButton />
      </Datagrid>
    </RAList>
  );
};

export default List;
