export { ADMIN } from "../constants";
export const API_URL = "/api";
export const RESOURCE: string = "sales/listing_fees";

const MODEL = "listingfee";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};
