import React from "react";
import {
  FileField,
  FileInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
} from "react-admin";
import {
  SimpleForm,
  required,
  TextInput,
  DateInput,
  NumberInput,
} from "react-admin";
import { MarketReferenceArrayInput } from "../../market";
import { HorizontalGroup } from "../../reusable";
import { WebsiteReferenceArrayInput } from "../../website";
import { YoutubeReferenceArrayInput } from "../../youtube/inputs";
import { BrandReferenceInput } from "../brands";
import { LISTING_FEE_OWNERS_CHOICES } from "../../constants";
import { PaymentStatus, PaymentTypes } from "./List";

const check_date = (value, allValues) => {
  let paymentStatus = allValues?.payment_status;

  if (paymentStatus === "paid" && !value) {
    return "Please Enter payment date";
  }

  return undefined;
};

export const Form: React.FC<any> = (props) => {
  return (
    <SimpleForm {...props} mutationMode="pessimistic" redirect={"list"}>
      <SelectArrayInput
        source="type"
        choices={[
          { id: "listing_fee", name: "Listing Fee" },
          { id: "flat_fee", name: "Flat Fee" },
          { id: "video_review", name: "Video Review" },
          { id: "youtube_top", name: "Youtube Top" },
        ]}
        id="listing_type"
        label="Select Listing Type"
        validate={[required()]}
      />
      <HorizontalGroup>
        <BrandReferenceInput
          source="brand"
          label="Brand"
          validate={[required()]}
        />
        <DateInput source="start_date" />
        <DateInput source="end_date" />
        <SelectInput source="fee_owner" choices={LISTING_FEE_OWNERS_CHOICES} />
      </HorizontalGroup>
      <HorizontalGroup>
        {/* THIS TWO Fields are depcricated */}
        {/* <WebsiteReferenceArrayInput source={"websites"} label="Website" />
        <YoutubeReferenceArrayInput source={"youtubes"} label="Youtube" /> */}
        <WebsiteReferenceArrayInput source={"website"} label="Website" />
        <YoutubeReferenceArrayInput source={"youtube"} label="Youtube" />
        <MarketReferenceArrayInput source="markets" label="Markets" />
      </HorizontalGroup>
      <HorizontalGroup>
        <SelectInput
          source="payment_type"
          choices={Object.keys(PaymentTypes).map((key) => ({
            id: key,
            name: PaymentTypes[key as keyof typeof PaymentTypes],
          }))}
        />
        <SelectInput
          source="payment_status"
          choices={Object.keys(PaymentStatus).map((key) => ({
            id: key,
            name: PaymentStatus[key as keyof typeof PaymentStatus],
          }))}
        />
        <DateInput source="paid" validate={[check_date]} />
        <DateInput source="fee_date" />
      </HorizontalGroup>
      <HorizontalGroup>
        <NumberInput
          onWheel={(e) => e.target.blur()}
          source="amount"
          parse={(value) => value}
          validate={[required()]}
        />
        <TextInput source="received_amount" defaultValue={""} />
        <TextInput source="currency" defaultValue={"EUR"} />
      </HorizontalGroup>

      <HorizontalGroup>
        <ArrayInput source="data.fees" label="Fees Breakdown">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                  <>
                    <SelectInput
                      {...rest}
                      source={getSource("type")} // Will translate to "data.fees[0].type"
                      choices={[
                        { id: "WEBSITE", name: "WEBSITE" },
                        { id: "YOUTUBE", name: "YOUTUBE" },
                      ]}
                      label="Product Type"
                    />
                    <HorizontalGroup>
                      {scopedFormData?.type === "WEBSITE" ? (
                        <WebsiteReferenceArrayInput
                          {...rest}
                          source={getSource("websites")}
                          label="Website"
                        />
                      ) : scopedFormData?.type === "YOUTUBE" ? (
                        <YoutubeReferenceArrayInput
                          {...rest}
                          source={getSource("youtubes")}
                          label="Youtube"
                        />
                      ) : (
                        <></>
                      )}
                      <MarketReferenceArrayInput
                        {...rest}
                        source={getSource("markets")}
                        label="Markets"
                      />
                      <NumberInput
                        {...rest}
                        label="Amount"
                        onWheel={(e) => e.target.blur()}
                        source={getSource("amount")}
                        parse={(value) => value}
                        validate={[required()]}
                      />
                      <TextInput
                        {...rest}
                        label="Currency"
                        source={getSource("currency")}
                        defaultValue={"EUR"}
                      />
                    </HorizontalGroup>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </HorizontalGroup>

      <HorizontalGroup>
        <TextInput source="contact_person" />
        <TextInput multiline source="information" />
      </HorizontalGroup>
      <HorizontalGroup label="Invoice">
        <TextInput source="invoice_number" />
        <TextInput multiline source="invoice_details" />
      </HorizontalGroup>
      <FileField source="invoice_url" title="Invoice Document" />
      <FileInput source="invoice" label="Upload Invoice">
        <FileField source="invoice_url" title="Invoice Document" />
      </FileInput>
    </SimpleForm>
  );
};
