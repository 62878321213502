/**
 * API URL
 */
export const API_URL = "/api";

export const ROLE = {
  writer: "writer",
  admin: "admin",
  staff: "staff",
  integrator: "integrator",

  is_staff: (role) => [ROLE.admin, ROLE.staff].includes(role),
};

export const SLACK_CHANNEL_DEFAULT = "eden-pm-other";

export const SLACK_CHANNELS = [
  "eden-pm-casino-fr",
  "eden-pm-forex-fr",
  "eden-pm-jp",
  "eden-pm-it",
  "eden-pm-es",
  "eden-pm-shopping",
  "proofreader-feedback",
  // "test_bot", // commenting out to use it in local
  SLACK_CHANNEL_DEFAULT,
];

export const SLACK_CHOICES = SLACK_CHANNELS.map(
  (channel) => ({
    id: channel,
    name: channel,
  }),
  {},
);

export const GROUPS = {
  editors: "editors",
  writers: "writers",
  editorsAndWriters: "editor_and_writer",
  integrators: "integrators",
  freelances: "freelances",
  productOwners: "product owners",
  linkbuilding: "linkbuilding",
  finance: "finance",
  outreach: "outreach",
  proofreader: "proofreaders",
  vAssistant: "vassistants",
  coupemonde: "coupemonde",
  changeWriterRates: "change_writer_rates",
  IPResolvers: "ip_resolvers",
  changeOutreachPrice: "change_outreach_price",
};

export const PERMISSION = {
  writerRate: {change: 'content.change_writerratehistory'},
}
/**
 * ROLES
 */

export const WRITER = ROLE.writer;
export const ADMIN = ROLE.admin;

/**
 * GROUPS
 */
export const EDITORS = GROUPS.editors;

/**
 * Content statuses
 */
export const STATUS = {
  planned: "planned",
  requested: "requested",
  review: "review",
  va_reviewed: "va_reviewed",
  proofreading: "proofreading",
  approved: "approved",
  published: "published",
  integrated: "integrated",
  double_checked: "double_checked",
  integration: "integration",
};

export const REQUESTED = STATUS.requested;
export const REVIEW = STATUS.review;
export const VAREVIEWED = STATUS.va_reviewed;
export const APPROVED = STATUS.approved;
export const PUBLISHED = STATUS.published;
export const INTEGRATED = STATUS.integrated;
export const INTEGRATING = STATUS.integration;

/**
 * Resources
 */

export const RESOURCE = {
  pbn: "website/pbn",
  pbnContent: "pbn/content",
  pbnOutreach: "pbn/outreach",
  website: "website/website",
  youtube: "youtube/youtube",
  clicky: "clicky/from_website",
  websiteWithStats: "website/with_stats/website",
  linkbuildingWebsite: "website/linkbuilding",
  market: "website/market",
  niche: "website/niche",
  vertical: "website/vertical",
  page: "page",
  WebsiteStats: "content/website/stats",
  YoutubeStats: "content/youtube/stats",
  YoutubeOrderStats: "youtube/youtube/stats",
  POStats: "content/po/stats",
  writerStats: "content/writer/stats",
  proofreaderStats: "content/proofreader/stats",
  integratorStats: "content/integrator/stats",
  writer: "content/writer",
  proofReader: "content/proofreader",
  vAssistantTask: "content/vassistant_task",
  vAssistant: "content/vassistant",
  order: "content/order",
  contentCharacter: "content/character",
  contentFeedbacks: "content/feedbacks",
  contentMinimal: "content/content/minimal",
  contentAssignIntegrator: "content/assign/integrator",
  contentTrelloIntegrator: "content/trello/integrator",
  contentSection: "content/section",
  contentTemplateV2: "content/template/v2",
  contentTemplate: "content/template",
  linkbuilding: "content/linkbuilding/order",
  user: "auth/users",
  integrators: "integration/integrator",
  integrationOrder: "integration/order",
  financeWriter: "finance/writer",
  financeProofreader: "finance/proofreader",
  financeVAssistant: "finance/vassistant",
  financePaymentWriter: "finance/payments/writer",
  financePaymentProofreader: "finance/payments/proofreader",
  financePaymentVAssistant: "finance/payments/vassistant",
  outreachFinanceSite: "outreach/finance",
  outreachLinkbuildingSite: "outreach/linkbuilding/site",
  outreachLinkbuildingOrder: "outreach/linkbuilding/order",
  outreachLinkbuildingLatestContent: "outreach/linkbuilding/latest_content",
  outreachLinkbuildingDuplicateIP: "outreach/linkbuilding/duplicate_ip",
  outreachLinkbuildingPIC: "outreach/linkbuilding/pic",
  outreachLinkbuildingRedirection:
    "outreach/linkbuilding/linkbuilding_redirect",
  outreachLinkbuildingIndex: "outreach/linkbuilding/index",
  outreachOrder: "outreach/order",
  outreachMonthlyPlan: "outreach/linkbuilding/monthly_plan",
  outreachTopics: "outreach/topics",
  flatPages: {
    writerGuidelines: "flat_pages/writer_guidelines",
  },
  notifications: {
    user: "notifications/user",
    feedback: "notifications/feedback",
  },
  flatFees: "sales/flat_fees",
  listingFees: "sales/listing_fees",
  deals: "sales/deals",
  brand_info: "sales/brand_info",
};

export const RES_MARKET = RESOURCE.market;
export const RES_NICHE = RESOURCE.niche;
export const RES_VERTICAL = RESOURCE.vertical;
export const RES_WEBSITE = RESOURCE.website;
export const RES_PAGE = RESOURCE.page;
export const RES_WRITER = RESOURCE.writer;
export const RES_ORDER = RESOURCE.order;
export const RES_USER = RESOURCE.user;

/**
 * Actions
 */
export const ACTIONS = {
  content: {
    published: (id) => `${API_URL}/${RESOURCE.order}/${id}/publish/`,
    trelloIntegration: () => `${API_URL}/${RESOURCE.order}/trello_integration/`,
    assignIntegrator: () => `${API_URL}/${RESOURCE.order}/assign_integrator/`,
  },
  vAssistantTask: {
    complete: (id) => `${API_URL}/${RESOURCE.vAssistantTask}/${id}/complete/`,
    approve: (id) => `${API_URL}/${RESOURCE.vAssistantTask}/${id}/approve/`,
  },
  page: {
    published: (id) => `${API_URL}/${RESOURCE.page}/${id}/published/`,
    check_unique_path: (id, path, website) => {
      const params = new URLSearchParams({
        path: path,
        website: website,
        id: id ? id : "",
      }).toString();
      const url = `${API_URL}/${RESOURCE.page}/check_unique_path/?${params}`;
      return url;
    },
  },
  integrator: {
    assign: (id) => `${API_URL}/${RESOURCE.integrators}/${id}/assign/`,
  },
  integrationOrder: {
    start: (id) => `${API_URL}/${RESOURCE.integrationOrder}/${id}/start`,
  },
  finance: {
    payWriter: (id) => `${API_URL}/${RESOURCE.financeWriter}/${id}/pay/`,
    payProofreader: (id) =>
      `${API_URL}/${RESOURCE.financeProofreader}/${id}/pay/`,
    payVAssistant: (id) =>
      `${API_URL}/${RESOURCE.financeVAssistant}/${id}/pay/`,
    payOutreachSite: (id) =>
      `${API_URL}/${RESOURCE.outreachFinanceSite}/${id}/pay/`,
  },
};

const _compose = (label, code) => ({
  label: label,
  code: code ? code : label,
});

/**
 * Website types
 */
export const WEBSITE_TYPES = {
  money: _compose("money site", "money site"),
  t1: _compose("Tier 1", "T1"),
  t2: _compose("Tier 2", "T2"),
  pbn: _compose("pbn", "pbn"),
  guestPost: _compose("Guest post", "gp"),
  seeding: _compose("seeding", "seeding"),
  top50PBN: _compose("top_50_pbn", "top_50_pbn"),
  parasiteSEO: _compose("parasite_seo", "parasite_seo"),
  undefined: _compose("undefined", "-"),
};

export const CONTENT_TYPE = {
  review: "review",
  optimization: "optimization",
  linkbuilding: "linkbuilding",
  video: "video",
  pbn: "pbn",
  other: "other",
};

export const CONTENT_TYPE_CHOICES = Object.values(CONTENT_TYPE).map(
  (value) => ({ id: value, name: value }),
);

export const WRITER_TYPE = {
  regular: "regular",
  pbn: "pbn",
  ai_writer: "ai_writer",
};

export const WRITER_TYPE_CHOICES = Object.values(WRITER_TYPE).map((value) => ({
  id: value,
  name: value,
}));

export const CONTENT_PRIORITY = {
  high: "high",
  emerging: "emerging",
  normal: "normal",
  default: "default",
};

export const CONTENT_PRIORITY_CHOICES = Object.values(CONTENT_PRIORITY).map(
  (value) => ({ id: value, name: value }),
);

// Outreach PIC users
export const PIC_USERS = [
  { value: "", label: "-" },
  { value: "karnkanee@greentomatomedia.com", label: "Jess" },
  { value: "anthony@greentomatomedia.com", label: "Anthony" },
  { value: "pauls@greentomatomedia.com", label: "Paul" },
  { value: "benjamin@greentomatomedia.com", label: "Benjamin" },
  { value: "leop@greentomatomedia.com", label: "Leo" },
  { value: "christopher@greentomatomedia.com", label: "Christopher" },
  { value: "paolo@greentomatomedia.com", label: "Paolo" },
  { value: "indya@greentomatomedia.com", label: "Anindya" },
];

export const PIC_USER_CHOICES = PIC_USERS.map((user) => ({
  id: user.value,
  name: user.label,
}));

// Listing Fee Owners
export const LISTING_FEE_OWNERS = [
  { value: "", label: "-" },
  {
    value: "clayton@greentomatomedia.com",
    label: "Clayton",
    color: "GREEN",
  },
  {
    value: "roberta@greentomatomedia.com",
    label: "Roberta",
    color: "YELLOW",
  },
  {
    value: "diane@greentomatomedia.com",
    label: "Diane",
    color: "RED",
  },
];

export const LISTING_FEE_OWNERS_CHOICES = LISTING_FEE_OWNERS.map((user) => ({
  id: user.value,
  name: user.label,
}));

// Virtual Assistant Task Status

export const TASK_STATUS = {
  requested: "requested",
  in_progress: "in_progress",
  finished: "finished",
  approved: "approved",
};

export const VASSISTANT_TASK_STATUS = [
  { value: "", label: "-" },
  { value: "requested", label: "Requested" },
  { value: "in_progress", label: "In Progress" },
  { value: "finished", label: "Finished" },
  { value: "approved", label: "Approved" },
];

export const VASSISTANT_TASK_STATUS_CHOICES = VASSISTANT_TASK_STATUS.map(
  (item) => ({
    id: item.value,
    name: item.label,
  }),
);
