import { SearchInput, SelectInput } from "react-admin";
import { MarketReferenceInput } from "../../../market";
import { CHANNEL, PRODUCT } from "../constants";
import { WebsiteReferenceInput } from "../../../website";
import { YoutubeReferenceInput } from "../../../youtube/inputs";

export const filters = [
  <SearchInput source="q" alwaysOn />,
  <WebsiteReferenceInput
    source={"website"}
    label={"Website"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <YoutubeReferenceInput
    source={"youtube"}
    label={"Youtube"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <MarketReferenceInput
    source={"market"}
    label={"Market"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <SelectInput
    label="Channel"
    source="channel"
    emptyValue=""
    resettable
    choices={CHANNEL}
    alwaysOn
    allowEmpty={false}
  />,
  <SelectInput
    label="Product"
    source="deal_product"
    choices={PRODUCT}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
];

export default filters;
