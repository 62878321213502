import React from "react";
import makeAccounts from "./accounts";
import makeBrands from "./brands";
import makePMBrands from "./pmBrands";
import makeNetwork from "./network";
import makeDeals from "./deals";
import makePMDeals from "./pmDeals";
import makeListingFees from "./listingFees/makeListingFees";
import makeLicence from "./licences";
import makeSalesUser from "./salesUser";
import makeList from "./lists";
import makePMUTMMedium from "./pmUTMMedium";
import makeWpLink from "./wpLinks/";
import makeLandingPage from "./landingPage";
import makeGlobalLandingPage from "./globalLandingPage";

export const makeSales = (permissions: any): React.ReactElement[] => {
  return [
    makeAccounts(permissions),
    makeBrands(permissions),
    makePMBrands(permissions),
    makeDeals(permissions),
    makeList(permissions),
    makePMDeals(permissions),
    makeNetwork(permissions),
    makeSalesUser(permissions),
    makeListingFees(permissions),
    makeLicence(permissions),
    makePMUTMMedium(permissions),
    makeWpLink(permissions),
    makeLandingPage(permissions),
    makeGlobalLandingPage(permissions),
  ];
};
