import * as React from "react";

import {
  SimpleForm,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  useUnselectAll,
  BooleanInput,
  AutocompleteInput,
  FunctionField,
} from "react-admin";
import { useFormState, useForm } from "react-final-form";

import { HorizontalGroup } from "../../reusable";
import { Button as MuiButton } from "@material-ui/core";

import { useStyles } from "../pmDeals/List/List";
import { WebsiteReferenceInput } from "../../website";
import { RESOURCE as LandingPage } from "../landingPage/constants";
import { GEOS } from "./constants";

const DisplayLinks = ({ selectedData, edit = false, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const form = useForm();
  const [generated, setGenerated] = React.useState(edit ? true : false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const unselectAll = useUnselectAll();
  const generate = (e) => {
    e.preventDefault();

    // check if the correct domain is selected for all landing page
    if (!values.domain) {
      setError("Please select the domain first");
      unselectAll(LandingPage);
      return;
    }
    let links = selectedData;
    if (values.links) {
      links = [...values.links, ...selectedData];
    }
    const domain = values.domain;
    const link_domains = links.map((link) => link.domain);
    if (!link_domains.every((link) => link === domain)) {
      setError("Please select the same domain for all landing pages");
      return;
    }

    // reset error and generate the links
    setError(undefined);
    // if `values` is empty then initialize it
    if (!values.data) {
      form.change("data", {});
      form.change("data.links", selectedData);
    } else {
      // if `values` is not empty then append to it
      let existingLinks = values.data.links || [];
      form.change("data", {});
      form.change("data.links", [...selectedData, ...existingLinks]);
    }

    setGenerated(true);
    unselectAll(LandingPage);
  };

  const clearForm = (e) => {
    e.preventDefault();
    delete values.data.links;
    setGenerated(false);
  };

  const globalValidation = (allValues) => {
    console.log("DEFAUT", allValues);
    // we should have one link and one link only as default link
    const defaultLinks = allValues.filter((link) => link.is_default === true);
    if (defaultLinks.length === 0) {
      return "Please select one link as default";
    }
    if (defaultLinks.length > 1) {
      return "Only one link can be selected as default";
    }
    const geos = allValues.filter((link) => link.geo).map((link) => link.geo);
    console.log("XXX", geos, geos.length);
    if (new Set(geos).size !== geos.length && geos.length > 1) {
      return "Duplicate GEOs are not allowed";
    }

    return undefined;
  };
  return (
    <>
      <MuiButton
        id="btn-generate"
        color="primary"
        variant="contained"
        onClick={generate}
      >
        Generate Links
      </MuiButton>
      {error && <p style={{ color: "red" }}>🚨 {error}</p>}
      {generated && (
        <>
          <MuiButton color="secondary" variant="contained" onClick={clearForm}>
            Clear Links
          </MuiButton>
          <HorizontalGroup>
            <h4>Links </h4>
            <ArrayInput
              source="data.links"
              validate={[required(), globalValidation]}
            >
              <SimpleFormIterator
                className={`${classes.flex} ${classes.itemsCenter} ${classes.disableFadeEnter}`}
                disableAdd
                disableReordering
              >
                <BooleanInput
                  source="is_default"
                  label="Select as Default Link"
                />
                <AutocompleteInput
                  label="GEO"
                  source="geo"
                  choices={GEOS}
                  validate={[required()]}
                />
                <TextInput
                  disabled
                  source="landing_page"
                  label="Landing Page"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </HorizontalGroup>
        </>
      )}
    </>
  );
};

const inputValidation = (value: string) => {
  if (value.includes(" ")) {
    return "Brand name should not contain spaces";
  }
  value = value.trim();
  if (value.startsWith("/")) {
    return "Slug should not start with '/'";
  }
  return undefined;
};

export default function Form({ selectedData, edit, ...props }) {
  return (
    <SimpleForm {...props}>
      <HorizontalGroup>
        <WebsiteReferenceInput
          label={"domain"}
          source="domain"
          validate={required()}
          filter={{ landing_page: true }}
        />
        <TextInput
          label="slug"
          source="slug"
          helperText="This will be the slag of the global landing page. Example: site.com/glp/slug"
          validate={[required(), inputValidation]}
        />
      </HorizontalGroup>
      <h4>Select Landing pages and then click Generate Links</h4>
      <DisplayLinks edit={edit} selectedData={selectedData} />
    </SimpleForm>
  );
}
