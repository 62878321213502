import React from "react";
import {
  useDelete,
  useCreate,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { RESOURCE as LISTING_FEE } from "./constants";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { Tooltip } from "@material-ui/core";
import copy from "copy-to-clipboard";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { post } from "../../utils";
import { API_URL, RESOURCE } from "./constants";
import { DeleteButtonWithConfirmation } from "../../reusable/buttons/DeleteButtonWithConfirmation";
interface ActionsProps {
  label: string;
}

export const Actions: React.FC<ActionsProps> = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [delete_, deleteState] = useDelete();
  const [create, state] = useCreate();
  const { id, ...data } = record;

  const handleDelete = React.useCallback(() => {
    delete_(LISTING_FEE, record.id);
  }, [delete_, record.id]);
  const handleClone = React.useCallback(() => {
    const _data = { ...data };
    // The `data` stores the listing fees breakdowns
    // in a key `fees` which contains a list of listing fees.
    const dataInternal = _data?.data;
    if (dataInternal) {
      _data.data = JSON.stringify(dataInternal);
    }
    // Delete the fileds that are not required for cloning
    delete _data.invoice;
    delete _data.invoice_url;
    delete _data.created;
    delete _data.updated;

    create(LISTING_FEE, _data);
    refetch();
  }, [create, data, refetch]);

  const sendSlackMessage = React.useCallback(async () => {
    try {
      await post(`${API_URL}/${RESOURCE}/${id}/send_slack_message/`, {});
      notify("Message sent to slack");
    } catch (error) {
      notify("Error while sending message to slack");
    }
  }, [id, notify]);

  React.useEffect(() => {
    if (state.loaded || deleteState.loaded) {
      if (state.error || deleteState.error) {
        notify(state.error || deleteState.error, "error");
      } else {
        refetch();
      }
    }
  }, [state.loaded, state.error, refetch, notify, deleteState]);

  return (
    <>
      <Tooltip title={"Clone"}>
        <IconButton onClick={handleClone}>
          <CallSplitIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Delete"}>
        <DeleteButtonWithConfirmation handleDelete={handleDelete} />
      </Tooltip>
      <Tooltip title={"Send Message to Slack"}>
        <IconButton color="primary" onClick={sendSlackMessage}>
          <ChatBubbleIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

interface ActionsProps {
  prettyLink: boolean;
  label: string;
}

export const LinkCopyAction: React.FC<ActionsProps> = ({
  label,
  prettyLink,
}) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleCopy = React.useCallback(() => {
    if (prettyLink) {
      copy(record.pretty_link);
    } else {
      copy(record.link);
    }
    notify("Copied successfully");
  }, [record.link, prettyLink, record.pretty_link, notify]);

  return (
    <>
      {record.link ? (
        <Tooltip
          title={`Copy ${prettyLink ? "Pretty Link" : "Affiliate Link"}`}
        >
          <IconButton onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

// export const LinkCopyAction: React.FC<ActionsProps> = () => {
//   const record = useRecordContext();
//   const notify = useNotify();

//   const handleCopy = React.useCallback(() => {
//     copy(record.link);
//     notify("Copied successfully");
//   }, [record.link, notify]);

//   return (
//     <>
//       {record.link ? (
//         <Tooltip title={"Copy Affilicate Link"}>
//           <IconButton onClick={handleCopy}>
//             <FileCopyIcon />
//           </IconButton>
//         </Tooltip>
//       ) : null}
//     </>
//   );
// };

export default Actions;
