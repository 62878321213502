import React from "react";
import { Edit as RaEdit, EditProps, useNotify, useRefresh } from "react-admin";
import { Form } from "./Form";

export const Edit = (props: EditProps): React.ReactElement => {
  const transform = (data: any) => {
    /**
     * invoice should only contain File. It it contains the string of the
     * file name, just delete it
     */
    if (typeof data.invoice === "string") {
      delete data.invoice;
    }

    return {
      ...data,
      data: JSON.stringify(data.data),
    };
  };
  return (
    <RaEdit {...props} transform={transform}>
      <Form />
    </RaEdit>
  );
};

export default Edit;
